const content ={};

content.ubicacion = 'UBICACIÓN: ';
content.ubicacion2 = 'Madrid, Cundinamarca';

content.email = 'EMAIL: ';
content.email2 = ' consolidationcenter@florising.com';
content.email3 = ' SALES@FLORISING.COM';

content.telefono = 'TELEFONO: ';
content.telefono2 = ' (+57) 321-4177057'
content.telefono3 = ' (+57) 310-6392396'

export {content}