const content ={}

content.title1 = 'FOLLAJES'
content.verde1 = 'RUSCUS'
content.verde2 = 'HELECHO'
content.verde3 = 'COCCULUS'
content.verde4 = 'LILY GRASS'
content.verde5 = 'EUCALIPTO BABY BLUE'
content.verde6 = 'GYPSO'
content.verde7 = 'BRILLANTINA'

content.title2 = 'VARIEDAD DE ROSAS'
content.flor1 = 'VENDELA'
content.flor2 = 'GOLDSTRIKE'
content.flor3 = 'CONNIE'
content.flor4 = 'FREEDOM'
content.flor5 = 'BRIGHTON'
content.flor6 = 'ORLANDO'
content.flor7 = 'ORANGE CRUSH'
content.flor8 = 'HIGH & MAGIC'
content.flor9 = 'ALEX'
content.flor10 = 'PINK MONDIAL'
content.flor11 = 'TOPAZ'
content.flor12 = 'MOODY BLUE'
content.flor13 = 'MOVIE STAR'
content.flor14 = 'PINK FLOYD'
content.flor15 = 'MONDIAL'
content.flor16 = 'BONITA'
content.flor17 = 'TABATA'
content.flor18 = 'FRUTTETO'
content.flor19 = 'MOMENTO'
content.flor20 = 'QUEEN BERRY'
content.flor21 = 'DEEP PURPLE'
content.flor22 = 'PLAYA BLANCA'
content.flor23 = 'VENDELA'
content.flor24 = 'BLUSH'
content.flor25 = 'CABARET'
content.flor26 = 'NEW FACE'

content.title3 = 'BOUQUET'
content.title4 = 'VARIEDADES DE CLAVEL'
content.clavel1 = 'HOTPINK'
content.clavel2 = 'ORANGE'
content.clavel3 = 'PINK'
content.clavel4 = "RED"
content.clavel5 = "WHITE"
content.clavel6 = 'YELOW'

content.title5 = 'VARIEDADES DE DIVERSIFICACIÓN'
content.diver1 = 'GIRASOL'
content.diver2 = 'PROTEAS'
content.diver3 = 'GERBERA'
content.diver4 = "CLAVEL"
content.diver5 = "ASTROMELIA"
content.diver6 = 'AGAPANTOS'
content.diver7 = 'BOCA DE DRAGÓN'
content.diver8 = 'ASTER'
content.diver9 = 'POMPON'
content.diver10 = 'HYDRANGEA'
content.diver11= 'LIRIOS'


export {content}