const content = {};

content.title1 = 'RECEPCIÓN Y CONSOLIDACIÓN EN FRÍO';
content.recepcionfrio1 = 
    `MATERIAL DE EMPAQUE:
    (Estibas, esquineros, zunchos)`;
content.recepcionfrio2 =    
    `ARMADO DE PALETS:
    (Organización y tratamiento adecuado ...)`;
content.recepcionfrio3 =
    `PRE-COOLING:
    (Ocho (8) unidades de pre-frío para pallets de hasta 
    2.5 mts de altura)`;
content.recepcionfrio4 =
    `CARGUE EN FRÍO CONTENEDOR:
    (Dos (2) muelles de carga con conexión directa al camión 
    garantizando la cadena de frío)`;

content.title2 = 'ALMACENAMIENTO EN FRÍO';
content.almacenamientoFrio =
    `Operaciones de Cross Docking. Capacidad
    de 460 mts³`;
content.almacenamientoFrio2 =
    `Refrigeración hasta -18°C`;

content.title3 = 'TRANSPORTE REFRIGERADO';
content.transporteRefrigerado =
    `Furgones tipo turbo, van y contenedor.
    parqueaderos aptos para maniobras de contenedores`;

content.title4 = 'OTROS SERVICIOS';
content.otros = 
    `Centro de negocios /
    Oficinas disponibles, Area Administrativa`;

export { content };
