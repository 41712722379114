<template>
    <nav class="NavBar">
        <div class="logo">
            <img src="../../assets/logo_florising-removebg-preview.png" alt="Logo">
        </div>
        <div class="links">
            <button @click="redirigir('/')">INICIO</button>

            <div class="desplegable">
                <button>SERVICIOS</button>
                <div class="links-menu">
                    <a @click="redirigir('/servicios-Centro-Consolidacion')">CENTRO DE CONSOLIDACIÓN</a>
                    <a @click="redirigir('/servicios-Floristeria')">COMERCIALIZADORA</a>
                </div>
            </div>
            <button @click="redirigir('/nosotros-FLORISING-COMMERCIAL-&-LOGISTICS-GROUP')">SOBRE NOSOTROS</button>
            <button @click="$event => redirigir('/contactanos')">CONTÁCTANOS</button>
        </div>

        <a @click.prevent="openNav()" class="menu" href="#"><button>Menu</button></a>

        <div class="overlay" id="mobile-menu">
            <a @click.prevent="closeNav()" href="" class="close">&times;</a>

            <div class="overlay-content">
                <a @click="redirigir('/')">INICIO</a>

                <div class="desplegable">
                    <a @click="toggleSubMenu('servicios')">SERVICIOS</a>
                    <div class="links-menu-mobile" v-if="subMenuOpen === 'servicios'">
                        <a @click="redirigir('/servicios-Centro-Consolidacion')">Centro de Consolidación</a>
                        <a @click="redirigir('/servicios-Floristeria')">Comercializadora</a>
                    </div>
                </div>

                <a @click="redirigir('/nosotros-FLORISING-COMMERCIAL-&-LOGISTICS-GROUP')">SOBRE NOSOTROS</a>

                <a @click="redirigir('/contactanos')">CONTÁCTANOS</a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            subMenuOpen: null
        };
    },
    methods: {
        redirigir(ruta) {
            this.$router.push(ruta);
            this.closeNav();
        },
        openNav() {
            document.getElementById("mobile-menu").style.width = "100%";
        },
        closeNav() {
            document.getElementById("mobile-menu").style.width = "0%";
        },
        toggleSubMenu(menu) {
            if (this.subMenuOpen === menu) {
                this.subMenuOpen = null;
            } else {
                this.subMenuOpen = menu;
            }
        },
    }
}
</script>

<style scoped>
@import './Nav.css';
</style>
