<template>
    <div class="pop-up">
        <div class="pop-up-inner">
            <div class="pop-up-close" @click="close()">
                &times;
            </div>
            <h1 class="titulo-flores">
                NUESTRAS FLORES
            </h1>
            <div class="separ"/>
            <section>
                <div class="nuestro-producto-flor-pop-up">
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-vendela.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor1 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-gold-strike.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor2 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-connie.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor3 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-freedom.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor4 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-brighton.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor5 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-orlando.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor6 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-orange-crush.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor7 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-high-magic.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor8 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-alex.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor9 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-pink-mondial.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor10 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-topaz.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor11 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-moody-blue.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor12 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-moviestar.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor13 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-pink-floyd.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor14 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-mondial.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor15 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-bonita.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor16 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-tabata.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor17 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-frutteto.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor18 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-momento.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor19 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-queen-berry.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor20 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-deep-purple.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor21 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-playa-blanca.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor22 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-vendela.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor23 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-blush.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor24 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-cabaret.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor25 }}</p>
                        </div>
                    </div>
                    <div>
                        <div class="imagen-flor-pop-up">
                            <img src="../../assets/Rosas/rosa-new-face.png" alt="">
                        </div>
                        <br>
                        <div class="contenido-flor">
                            <p>{{ content.flor26 }}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="section">

                        <button @click="close()">
                           CERRAR  
                        </button>
                    </div>
                </div>
            </section>
            <div class="separ"/>
        </div>
    </div>
</template>

<script>
    import {content} from '../../views/productos/Productos'
    export default{
        data:()=>({
            content: content
        }),
        methods:{
            close(){
                this.$emit('close')
            }
        }
    }
</script>

<style>
    @import './PopUpFlores.css';
</style>