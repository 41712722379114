const content2 = {};

content2.title = `FLORISING CLG SAS`;

content2.titleMision = `MISIÓN`;
content2.content1 = 
    `Somos una empresa productora, comercializadora y exportadora del sector 
    floricultor, cuyo objetivo principal es satisfacer las necesidades de 
    nuestros clientes, cumpliendo estándares de alta calidad a nivel nacional 
    e internacional. Basados en nuestra experiencia y trayectoria en el mercado, 
    nos destacamos por nuestros principios de ética y dando cumplimiento a nuestras 
    políticas de sostenibilidad. Contamos con personal competente, calificado y 
    altamente comprometido con el medio ambiente.`;

content2.titleVision = `VISIÓN`;
content2.content2 = 
    `Ser reconocidos en el año 2025 como la empresa líder en cada una de nuestras 
    líneas de trabajo, posicionando la compañía y cada uno de los productos y 
    servicios que tenemos como los primeros a nivel nacional e internacional, 
    a través del mejoramiento continuo, brindando siempre respaldo y confianza 
    a nuestros clientes.`;

content2.tituloQuienesSomos = `QUIÉNES SOMOS`;
content2.content3 = `FLORISING CLG SAS se ha caracterizado por cultivar y comercializar 
hermosas flores, brindando a nuestros clientes una excelente calidad. A lo largo de 
estos años, nuestra empresa se ha encargado de la diversificación de productos para su comercialización.`;

content2.tituloValores = `NUESTROS VALORES`;
content2.content4 = `Creemos en los valores que nos enseñaron nuestros padres y abuelos, 
brindando valores fundamentales como la Calidad, Puntualidad, Pasión, Competitividad, 
Trabajo en equipo, Orientación al cliente y Responsabilidad social.`;
content2.titulorigth = `Floristería`;
content2.tituloleft = `Consolidación`;

export {content2};
