const content = {};
content.content0 = 
    `Desde 2012, FLORISING COMMERCIAL & LOGISTIC GROUP SAS, 
    con la nueva razón social ha dirigido sus esfuerzos para 
    garantizar una cadena de frío. Esta idea surge de la 
    necesidad de servir inicialmente en el campo de las 
    exportaciones de productos perecederos. Creada por el 
    señor Víctor Raúl Carvajal Cuta, donde se asocia con 
    cargo máster y hacen del centro de consolidación uno 
    de los más grandes de la sabana en manejo de marítimos.`;
content.title = `QUIENES SOMOS`;
content.content1 = 
    `FLORISING CLG SAS se ha caracterizado 
    por cultivar y comercializar hermosas flores, 
    brindando a nuestros clientes una excelente calidad. 
    A lo largo de estos años, nuestra empresa se ha encargado 
    de la diversificación de productos para su comercialización.`;

content.title1= `NUESTROS SERVICIOS`;
content.title2= `ALMACENAMIENTO EN FRÍO`;
content.content3 = `Operaciones de Cross Docking. Capacidad
de 460 Mts³. Refrigeración hasta -18°C.`;
content.title3= `RECEPCIÓN Y CONSOLIDACIÓN EN FRÍO`;
content.content4 = `Material de empaque
(Estibas, esquineros, zunchos...) Armado de pallets
(Organización y tratamiento adecuado...)`;
content.title4= `OTROS SERVICIOS`;
content.content5 = `Centro de negocios / Oficinas disponibles.`;
content.title5 = `COMERCIALIZACIÓN FOLLAJES`;
content.content6 = `Ofrecemos productos verdes como Helecho, Ruscus,
Lily Grass y Cocculus.`;
content.title6 = `PRODUCTOS FLORES ROSAS Y BOUQUETS`;
content.content7 = `Ofrecemos diferentes tipos de rosas, tales como
Rosa Alex, Rosa Pink, Rosa Orange y muchas más. Contamos
con bouquets para toda ocasión.`;
content.title7 = `COMERCIALIZADORA`;
content.content8 = `FLORISING CLG SAS se ha caracterizado por cultivar y
comercializar hermosas flores, brindando a nuestros clientes una excelente
calidad. A lo largo de estos años, nuestra empresa se ha encargado de la
diversificación de productos para su comercialización.`;

export {content}