const content = {};

content.title = `SOBRE NOSOTROS`;
content.titleMision = `MISIÓN`;
content.content1 = 
    `Hemos establecido un centro de carga en frío, con el fin de brindar el
    servicio de almacenamiento y consolidación, para las diferentes empresas
    que necesiten ubicar sus productos bajo condiciones controladas de
    temperatura, de acuerdo a los requerimientos de cada una de las
    compañías para mantener en óptimas condiciones la calidad de sus
    productos y la cadena en frío.
    Almacenamos artículos perecederos en cuartos fríos especializados que
    mantienen los productos en óptimas condiciones. Analizamos la logística de
    cada orden individual, atendiendo a las necesidades del producto y del
    cliente, buscando siempre el mejoramiento continuo en la prestación de
    nuestros servicios.`;
content.titleVision = `VISIÓN`;
content.content2 = 
    `Nuestra infraestructura física y digital está diseñada para
    ofrecer servicios de alta calidad enfocados en procesos
    de mejoramiento continuo; generando cada vez más
    empleo y brindando un servicio de calidad.
    Contamos con estándares técnicos de calidad que nos
    han permitido hacer alianza estratégica con nuestros
    clientes y ofrecerles en el menor tiempo posible los
    mejores niveles de satisfacción, evitándoles costos
    innecesarios y riesgos en los procesos, siendo amigables
    con el medio ambiente.`;
content.titleReseñaHistorica = `RESEÑA HISTÓRICA FLORISING CLG`;
content.content3 = 
    `Desde 2012, FLORISING COMMERCIAL & LOGISTIC GROUP SAS, con
    la nueva razón social ha dirigido sus esfuerzos para garantizar una
    cadena de frío. Esta idea surge de la necesidad de servir inicialmente
    en el campo de las exportaciones de productos perecederos. Creada
    por el señor Víctor Raúl Carvajal Cuta, donde se asocia con cargo
    máster y hacen del centro de consolidación uno de los más grandes
    de la sabana en manejo de marítimos.`;
content.content4 = 
    `En el año 2017, se termina la sociedad, pero siguen funcionando. Es
    así que, con más de 10 años en el mercado, logramos consolidar
    aproximadamente 15 clientes potenciales, quienes siguen
    premiando nuestros esfuerzos por entender sus necesidades y
    satisfacer sus requerimientos respondiendo con resultados óptimos,
    en tiempo récord y con los mejores precios del mercado.`;
content.tituloObjetivos = `OBJETIVOS DEL SGCS BASC`;
content.content5 = `Florising Commercial & Logistics Group S.A.S, en 
concordancia con su Política de Seguridad, establece los siguientes Objetivos
como un compromiso con la seguridad en sus operaciones de consolidación de carga.`;
content.satisfacerNecesidades = `Satisfacer las necesidades y expectativas de las
partes interesadas según su contexto organizacional a través de la prestación
de un servicio con excelente calidad y seguridad en las operaciones de 
consolidación de carga.`;
content.prevencion = `Prevenir que se presenten novedades en el desarrollo de la
operación relacionada con actividades ilícitas, lavado de activos, financiamiento
del terrorismo, contrabando, conspiraciones internas, proliferación de armas,
corrupción y soborno en la organización.`;
content.cultura = `Promover una cultura de prevención de gestión de riesgo y
mejora continua de todos los procesos de SGCS, mediante la generación de espacios de
participación de todos los miembros de la organización.`;
content.reaccionEficaz = `Reaccionar eficaz y profesionalmente ante novedades y
siniestros, sean internos o en las organizaciones cliente, aplicando los métodos
y correctivos necesarios para evitar su repetición.`;

content.tituloPoliticas = `POLÍTICA DE GESTIÓN EN CONTROL Y SEGURIDAD`;
content.content6 = `Florising Commercial & Logistics Group S.A.S, haciendo parte
de la cadena de suministro, presta sus servicios de consolidación de carga y se destaca
por su firme compromiso de satisfacer las expectativas de sus clientes. Está comprometida con:`;
content.MantenerIntegridad = `Mantener la integridad en cada uno de sus procesos.`;
content.prevencionActividades = `Prevenir las actividades ilícitas en cada uno de estos,
así como prevenir la corrupción y el soborno.`;
content.CumplirRequisitos = `Cumplir los requisitos legales aplicables y todos aquellos
que la organización suscriba.`;
content.MejoraContinua = `Mejorar continuamente el Sistema de Gestión en Control
y Seguridad SGCS BASC.`;
content.ProtegerColaboradores = `Proteger a sus colaboradores, instalaciones, la carga,
a los asociados de negocio y demás partes interesadas que interactúan con la organización.`;

export {content};
